import { render, staticRenderFns } from "./NewEditClient.vue?vue&type=template&id=4172f726&scoped=true&"
import script from "./NewEditClient.vue?vue&type=script&lang=js&"
export * from "./NewEditClient.vue?vue&type=script&lang=js&"
import style0 from "./NewEditClient.vue?vue&type=style&index=0&id=4172f726&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4172f726",
  null
  
)

export default component.exports