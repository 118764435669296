<template>
  <div class="row">
    <div class="col-12">
      <h2 v-if="!this.$route.params.id">Cadastro de Clientes</h2>
      <h2 v-if="this.$route.params.id">Atualização de Clientes</h2>

      <Card>
        <h4>Dados Pessoais</h4>
        <form @submit.prevent="saveClient()" autocomplete="off">
          <div class="row">
            <div class="col">
              <base-input
                v-model="form.name"
                type="text"
                placeholder="Nome Completo"
                label="Nome"
              >
                <HasErrorForm
                  :form="form"
                  field_name="name"
                  slot="helperText"
                />
              </base-input>

              <div class="form-row">
                <h5>Motorista</h5>
                <div class="col-md-12">
                  <el-select
                    filterable
                    v-model="form.driver_id"
                    placeholder="Motoristas"
                    @change="paintWhite"
                  >
                    <el-option
                      v-for="driver in drivers"
                      :value="driver.id"
                      :label="driver.name"
                      :key="driver.id"
                    ></el-option>
                  </el-select>
                </div>
                <base-input label="CPF" class="col-12">
                  <the-mask
                    v-model="form.cpf"
                    class="form-control"
                    placeholder="CPF"
                    :mask="['###.###.###-##']"
                    slot="default"
                  />
                  <HasErrorForm
                    :form="form"
                    field_name="cpf"
                    slot="helperText"
                  />
                </base-input>
                <base-input label="Telefone" class="col-12">
                  <the-mask
                    v-model="form.cell_phone"
                    class="form-control"
                    placeholder="Telefone"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    slot="default"
                  />
                  <HasErrorForm
                    :form="form"
                    field_name="cell_phone"
                    slot="helperText"
                  />
                </base-input>

                <base-input
                  v-model="form.email"
                  type="text"
                  class="col-12"
                  placeholder="Email"
                  label="Email"
                  autocomplete="off"
                >
                  <HasErrorForm
                    :form="form"
                    field_name="email"
                    slot="helperText"
                  />
                </base-input>
                <base-input
                  v-model="form.password"
                  type="text"
                  class="col-12"
                  placeholder="Senha para o Cliente"
                  label="Senha para o Cliente"
                  autocomplete="off"
                >
                  <HasErrorForm
                    :form="form"
                    field_name="password"
                    slot="helperText"
                  />
                </base-input>
              </div>
            </div>

            <div class="col">
              <div class="form-row">
                <base-input type="text" class="col-12" label="CEP">
                  <the-mask
                    v-model="form.cep"
                    class="form-control"
                    placeholder="CEP"
                    :mask="['#####-###']"
                    slot="default"
                  />
                  <HasErrorForm
                    :form="form"
                    field_name="cep"
                    slot="helperText"
                  />
                </base-input>

                <base-input
                  v-model="form.street"
                  type="text"
                  class="col-12"
                  placeholder="Endereço"
                  label="Endereço"
                >
                  <HasErrorForm
                    :form="form"
                    field_name="address"
                    slot="helperText"
                  />
                </base-input>

                <base-input
                  v-model="form.number"
                  type="number"
                  class="col-12"
                  placeholder="Número"
                  label="Número"
                >
                  <HasErrorForm
                    :form="form"
                    field_name="address_number"
                    slot="helperText"
                  />
                </base-input>

                <base-input
                  v-model="form.reference"
                  type="text"
                  class="col-12"
                  placeholder="Referência"
                  label="Referência"
                >
                  <HasErrorForm
                    :form="form"
                    field_name="address_reference"
                    slot="helperText"
                  />
                </base-input>
              </div>
               <div class="form-row">
                  <div class="col-4">
                    <base-dropdown
                      title-classes="btn btn-warning btn-block"
                      titleTag="div"
                      :title="
                        selectedProvince
                          ? `Estado: ${selectedProvince.name}`
                          : 'Estado'
                      "
                    >
                      <a
                        v-for="province in provinces"
                        :key="province.id"
                        class="dropdown-item"
                        href="#"
                        @click="selectProvince(province)"
                        >{{ province.name }}</a
                      >
                    </base-dropdown>
                  </div>

                  <div class="col-4">
                    <base-dropdown
                      title-classes="btn btn-warning btn-block"
                      titleTag="div"
                      :title="
                        selectedCity ? `Cidade: ${selectedCity.name}` : 'Cidade'
                      "
                    >
                      <a v-if="!selectedProvince" class="dropdown-item" href="#"
                        >Selecione um Estado</a
                      >
                      <a
                        v-if="selectedProvince && cities.length <= 0"
                        class="dropdown-item"
                        href="#"
                        >Nenhuma cidade encontrada</a
                      >
                      <span v-if="selectedProvince && cities.length > 0">
                        <a
                          v-for="city in cities"
                          :key="city.id"
                          class="dropdown-item"
                          href="#"
                          @click="selectCity(city)"
                          >{{ city.name }}</a
                        >
                      </span>
                    </base-dropdown>

                    <HasErrorForm
                      :form="form"
                      field_name="city_id"
                      slot="helperText"
                    />
                  </div>

                  <div class="col-4">
                    <base-dropdown
                      title-classes="btn btn-warning btn-block"
                      titleTag="div"
                      :title="
                        selectedDistrict
                          ? `Bairro: ${selectedDistrict.name}`
                          : 'Bairro'
                      "
                    >
                      <a v-if="!selectedCity" class="dropdown-item" href="#"
                        >Selecione uma Cidade</a
                      >
                      <a
                        v-if="selectedCity && districts.length <= 0"
                        class="dropdown-item"
                        href="#"
                        >Nenhum Bairro encontrado</a
                      >
                      <span v-if="selectedCity && districts.length > 0">
                        <a
                          v-for="district in districts"
                          :key="district.id"
                          class="dropdown-item"
                          href="#"
                          @click="selectDistrict(district)"
                          >{{ district.name }}</a
                        >
                      </span>
                    </base-dropdown>
                  </div>
                </div>
            </div>
          </div>
          <div class="text-right">
            <base-button
              native-type="submit"
              type="warning"
              :disabled="loading"
            >
              Salvar
            </base-button>
          </div>
        </form>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from 'vform'
import Card from '@/components/Cards/Card'
import { Select, Option } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import { TheMask } from 'vue-the-mask'

import { BaseDropdown } from '@/components'
import BaseInput from '@/components/Inputs/BaseInput'

import HasErrorForm from '@/components/HasErrorForm'

import axios from 'axios'

export default {
  name: 'NewEditClient',
  data: () => ({
    form: false,
    isActive: 'active',
    provinces: [],
    cities: [],
    districts: [],
    drivers: [],
    district_shifts: [],
    district_shifts_ids: [],
    schools: [],
    shifts: [],
    schools_shift: [],
    schools_shifts_ids: [],
    selectedDriver: false,
    selectedProvince: false,
    selectedCity: false,
    selectedDistrict: false,
    driver_id: false,
    image_preview: null,
    loading: false
  }),
  methods: {
    selectSchools() {
      this.loadSchoolShifts()
    },
    selectSchoolShift(school, shift_id) {
      const shiftIndex = this.schools_shifts_ids.findIndex(
        s => s.shift_id === shift_id && s.school_id === school.id
      )

      if (shiftIndex >= 0) {
        this.schools_shifts_ids.splice(shiftIndex, 1)
      } else {
        this.schools_shifts_ids.push({ shift_id, school_id: school.id })
      }
      this.paintWhite()
    },
    async onImageChange(file) {
      if (file === null && this.image_preview) {
        this.image_preview = null
        await this.form.delete(`/drivers/${this.driver_id}/image`)
      }

      this.form.avatar_url = file
    },
    resetLocation() {
      this.selectedCity = false
      this.selectedDistrict = false
      this.form.city_id = null
      this.form.district_id = null
    },
    async selectProvince(province) {
      this.selectedProvince = province
      const {
        data: { cities }
      } = await this.$http.get(`cities/${province.id}`)
      this.cities = cities
    },
    async selectDriver(driver) {
      this.selectedDriver = driver
      const {
        data: { drivers }
      } = await this.$http.get('drivers')
      this.drivers = drivers
    },
    async selectCity(city) {
      this.selectedDistrict = false

      this.selectedCity = city
      const {
        data: { districts }
      } = await this.$http.get(`districts/${city.id}`)
      this.districts = districts

      const {
        data: { schools }
      } = await this.$http.get(`schools/${city.id}`)
      this.schools = schools
      this.loadSchoolShifts()

      const {
        data: { drivers }
      } = await this.$http.get('drivers')
      this.drivers = drivers
    },
    loadSchoolShifts() {
      this.schools_shift = this.schools
        .filter(f => this.form.work_schools_id.includes(f.id))
        .map(sh => {
          return {
            id: sh.id,
            name: sh.name,
            ids: this.schools_shifts_ids
              .filter(s => s.school_id === sh.id)
              .map(shift => shift.shift_id)
          }
        })

      this.paintWhite()
    },
    paintWhite() {
      setTimeout(() => {
        const elements = document.querySelectorAll('.el-select__tags-text')
        elements.forEach(element => {
          element.style.color = 'white'
        })
      }, 100)
    },
    // tudo relacionado a Bairros

    getDistrictById(id) {
      return this.districts.find(district => district.id === id)
    },

    getShiftById(id) {
      return this.shifts.find(shift => shift.id === id)
    },
    selectDistrict(district) {
      this.selectedDistrict = district
      this.form.city_id = this.selectedCity.id
      this.form.district_id = district.id
    },
    getDistrictName(district_id) {
      const district = this.districts.find(d => d.id === district_id)
      return district ? district.name : ''
    },
    districtShiftSelected(district, shift) {
      if (!district || !shift) {
        console.error('District or shift is undefined')
        return
      }

      return this.district_shifts_ids.find(
        ds => ds.district_id === district.id && ds.shift_id === shift.id
      )
    },

    selectDistrictShift(district_id, shift_id) {
      const shiftIndex = this.district_shifts_ids.findIndex(
        ds => ds.district_id === district_id && ds.shift_id === shift_id
      )

      if (shiftIndex >= 0) {
        this.district_shifts_ids.splice(shiftIndex, 1)
      } else {
        this.district_shifts_ids.push({ district_id, shift_id })
      }
    },

    async saveClient() {
      this.loading = true
      try {
        this.form.is_active = this.isActive === 'active' ? 1 : 0

        // subtitui o plugin formatando manualmente o object-to-formdata
        const dataObj = new FormData()
        Object.keys(this.form).map(key => {
          dataObj.append(key, this.form[key])
        })
        const route = this.$route
        if (route.params.id) {
          await axios.post(`admin/clients/update/${route.params.id}`, dataObj)
        } else {
          await axios.post('/admin/clients/store', dataObj)
        }

        this.$notify({
          type: 'primary',
          message: this.driver_id
            ? 'Cliente atualizado!'
            : 'Cliente Criado!',
          icon: 'tim-icons icon-bell-55'
        })

        this.$router.push({ path: '/clients' })
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    }
  },

  async created() {
    const {
      data: { provinces }
    } = await this.$http.get('provinces')
    this.provinces = provinces

    const { data } = await this.$http.get('shifts')
    this.shifts = data.data

    const districts = await this.$http.get('districts')
    this.districts = districts.data.data.data

    const schools = await this.$http.get('schools')
    this.schools = schools.data.data.data

    const drivers = await this.$http.get('drivers?paginate=false')
    this.drivers = drivers.data.data

    if (this.$route.params.id !== undefined) {
      this.driver_id = this.$route.params.id

      const response = await this.$http.get(`/admin/clients/${this.$route.params.id}`)
      const data = response.data.data
      // rest of the form data
      this.form = new Form({
        name: data.name,
        cnh: data.cnh,
        cpf: data.cpf,
        email: data.email,
        cep: data.address.zipcode,
        number: data.address.number,
        driver_id: data.client.driver_id,
        street: data.address.street,
        reference: data.address.reference,
        city_id: data.address.city_id,
        cell_phone: data.cell_phone,
        information: data.information,
        age: data.age,
        imei: data.imei,
        work_districts_id: data.work_districts_id,
        work_schools_id: data.work_schools_id
      })
      console.debug(this.form)

      if (data.avatar) {
        this.image_preview = data.avatar
      }

      this.isActive = parseInt(data.is_active) ? 'active' : 'inactive'
      this.schools_shifts_ids = data.schools_shifts_ids

      await this.selectProvince(
        this.provinces.find(p => p.id === data.address.city.province_id)
      )
      await this.selectDriver(
        this.drivers.find(d => d.id === data.client.driver_id)
      )
      await this.selectCity(this.cities.find(c => c.id === data.address.city_id))
      await this.selectDistrict(
        this.districts.find(c => c.id === data.address.district_id)
      )
    } else {
      this.form = new Form({ school_id: 1, shift_id: 1, plan_id: 1 })

      await this.selectProvince(this.provinces.find(p => p.initials === 'PR')) // Paraná
      await this.selectCity(this.cities.find(c => c.ibge_code === '4108304')) // Foz do Iguaçu
      await this.selectDriver(this.form.driver_id)
    }
  },

  computed: {
    ...mapState('auth', ['user'])
  },
  components: {
    Card,
    BaseInput,
    BaseDropdown,
    [Select.name]: Select,
    [Option.name]: Option,
    HasErrorForm,
    TheMask
  }
}
</script>

<style lang="scss" scoped>
.textarea {
  .textarea {
    border-radius: 6px;
    border: 1px solid rgba(29, 37, 59, 0.5);
  }

  .textarea:focus {
    border-radius: 6px;
    border: 1px solid #1986aa;
  }
}
</style>
